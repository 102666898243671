import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';
import { useIsReducedMotion } from '@atlaskit/motion';

export const useNoticeableCreateButtonExperiment = () => {
	const isReducedMotion = useIsReducedMotion();
	const [expConfig, fireExperimentExposure] = UNSAFE_noExposureExp(
		'cc_more_noticeable_create_button',
	);
	// Reduced motion users will not see any animation, so they should not be included in the experiment group
	!isReducedMotion && fireExperimentExposure();
	return expConfig.get('design', 'control');
};
